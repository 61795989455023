import React from 'react'; 
import './plans.css'; 

const plans = () => {
  return (
	  <div className="plans" id='plans'>
		  <h3>Planes</h3>
		  <span class="divider grey-bg center">
			  <span class="main-bg-color"></span>
		  </span>

		  <span className="plans-text">
			  Programe una consulta gratuita para discutir su proyecto.
			  Obtenga un presupuesto transparente adaptado a sus necesidades específicas.
		  </span>

		  <div className="prices">
			  <div className="price-item">
				  <h5>Desarrollo Web</h5> 
				  <span className='money'>$7,500</span>
				  <h4>
				  	Sitios web que convierten: atraiga, interactúe e impulse resultados con una poderosa presencia en línea
				  </h4>
				  <span>
					  Ideal para: Startups y pequeñas empresas con una visión clara.
				  </span>
				  <span>
				  Incluye: 
					  <ul>
						  <li>Diseño y desarrollo de sitios web personalizados</li>
						  <li>Optimización para dispositivos móviles</li>
						  <li>Configuración básica de SEO</li>
						  <li>Integración de formularios de contacto </li>
						  <li>Optimización de contenido en la página para una página de destino clave</li>
						  <li>Hosting Sin Limite</li>
						  <li>Domain</li>
					  </ul>
				  </span>
				  <a href="http://wa.me/524771598183?text=Estoy%20interesado%20en%20Proyecto%20Web" className="btn btn-quote" target="_blank" rel='noreferrer'>Más Información</a>

			  </div>
			  <div className="price-item">
			  <h5>SEM & Campañas en Redes Sociales </h5> 
				  <span className='money'>$2,000</span>
				  <h4>Llegue al público adecuado y obtenga resultados más rápido </h4>
				  <span>
					  Libérese de las limitaciones orgánicas con nuestra experiencia combinada en SEM (marketing en motores de búsqueda) y
					  publicidad en redes sociales. 
				  </span>
				  <span>
					  Incluye: 
					  <ul>
						  <li>Investigación y optimización de palabras clave</li>
						  <li>Segmentación de audiencias en redes sociales</li>
						  <li>Creación de textos publicitarios y pruebas A/B</li>
						  <li>Optimización de la página de destino</li>
						  <li>Gestión básica de campañas</li>
						  <li className='liSmall'>
							  (Costo sólo por manejo de campaña)
						  </li>
					  </ul>
				  </span>
				  <a href="http://wa.me/524771598183?text=Estoy%20interesado%20en%20Proyecto%20Web" className="btn btn-quote" target="_blank" rel='noreferrer'>Más Información</a>
			  </div>
			  <div className="price-item">
			  <h5>Local SEO</h5> 
				  <span className='money'>$3,500</span>
				  <h4>
					  Sea encontrado en línea, crezca localmente: nuestros servicios de SEO local brindan resultados.
					 
				  </h4>
				  <span>
					  Nuestros servicios expertos de SEO local optimizarán su presencia en línea para garantizar que los clientes que buscan empresas en su área lo descubran.
				  </span>
				  <span style={{marginTop:"-3vh"}}>
					  Ideal para: Empresas nuevas y aquellas con mínima presencia online.
				  </span>
				  <span style={{marginTop:"-1vh"}}>
					  Incluye:
					  <ul>
						  <li>Optimización del perfil de Google My Business</li>
						  <li>Página Web</li>
						  <li>Creación de citas locales en directorios relevantes</li>
						  <li>SEO en la página para orientación geográfica</li>
						  <li>Informe mensual para el análisis de los resultados</li>
					  </ul>
				  </span>
				  <a href="http://wa.me/524771598183?text=Estoy%20interesado%20en%20Proyecto%20Web" className="btn btn-quote" target="_blank" rel='noreferrer'>Más Información</a>
			  </div>
		  </div>
	</div>
  )
}

export default plans