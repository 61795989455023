import React from 'react'; 
import './about.css'; 


const about = () => {
  return (
	  <div className="about" id='about'>
		  <div className="about-grid">
			  <h2>Compañia</h2>
			  <span>
				  Somos expertos en <strong> Desarrollo Web y Marketing Digital</strong>.
				  <p>Nos apasiona crear <strong>desarrollos digitales</strong> innovadores que impulsen el crecimiento de tu negocio.</p>
				  <p>Te ayudamos a destacar en el mundo digital a través de estrategias de <strong>marketing digital</strong>efectivas.</p>
				  <p>Impulsamos tu visibilidad en línea, generamos leads cualificados y aumentamos tu retorno de inversión.</p>
			  </span>
		  </div>
		  <div className="about-grid">
			  <h2>Misión</h2>
			  <span>
				  <p>Impulsar el éxito digital de nuestros clientes a través de soluciones innovadoras en <strong>Desarrollo Web y Marketing Digital</strong>.</p>
				<p> Creamos experiencias online excepcionales que generan resultados tangibles, fortaleciendo la presencia digital de nuestros clientes y posicionándolos como líderes en su industria. </p> 
			  </span>
		  </div>
		  <div className="about-grid">
			  <h2>Visión </h2>
			  <span>
				<p>Ser líderes en el mercado de <strong>Desarrollo Web y Marketing Digital</strong>, ofreciendo soluciones innovadoras que superen las expectativas de nuestros clientes.</p>
				<p>Queremos ser reconocidos como socios estratégicos en el crecimiento digital de las empresas, impulsando su éxito a través de <strong>estrategias digitales</strong> efectivas y experiencias online excepcionales.</p>
			  </span>
		  </div>
		  <div className="about-grid-procesos">
			  <h2>Proceso de Desarrollo</h2>
			  <div className="procesos-container">
				  <div className='proceso'>
						  <h3>Planificación Estratégica</h3>
							<p>Análisis profundo de tu negocio, objetivos y público objetivo.</p>
							<p>Desarrollo de una estrategia digital integral.</p>  
							<p>Identificación de las plataformas y canales más relevantes.</p>
							<p>Definición de la arquitectura del sitio web.</p>
				  </div>
				  <div className='proceso'>
					  	<h3>Diseño y Desarrollo Web</h3>
				  			<p>Creación de un diseño web atractivo y funcional.</p>
							<p>Desarrollo de un sitio web responsive y optimizado para SEO.</p>
							<p>Integración de elementos interactivos y call-to-actions.</p>
				  		
				  </div>
				  <div className='proceso'>
					<h3>Implementación de Marketing Digital</h3>
							<p>Definición de las estrategias de SEO, SEM, redes sociales y email marketing.</p>
							<p>Creación de contenido relevante y optimizado.</p>
							<p>Configuración y gestión de campañas publicitarias.</p>
				  </div>

				  <div className='proceso'>
						<h3>Seguimiento y Soporte</h3>
							<p>Monitoreo constante del rendimiento de la estrategia digital.</p>
							<p>Análisis de datos y métricas clave.</p>
							<p>Implementación de mejoras y optimizaciones continuas.</p>
							<p>Acompañamiento y soporte técnico continuo.</p>
							<p>Medición de resultados y generación de informes.</p>
							<p>Adaptación de la estrategia según los cambios del mercado.</p>
					
				  </div>
				  </div>
		  </div>
	</div>
  )
}

export default about