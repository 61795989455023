import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header';
import Hero from './components/hero'; 
import About from './components/about'; 
import Development from './components/development'; 
import Marketing from './components/marketing'; 
import Languages from './components/languages'; 
import Frameworks from './components/frameworks'; 
import Plans from './components/plans'; 
import Testing from './components/testing'; 
import Footer from './components/footer';
import Projects from './components/projects';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Hero></Hero>
      <About></About>
      <Development></Development>
      <Marketing></Marketing>
      <Languages></Languages>
      <Frameworks></Frameworks>
      <Plans></Plans>
      <Testing></Testing>
      <Projects></Projects>
      <Footer></Footer>
    </div>
  );
}

export default App;
