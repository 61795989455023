import React from 'react'
import './marketing.css'

const marketing = () => {
  return (
	  <div className="marketing" id='marketing'>
		  <h1>Marketing Digital</h1>
		  <div className="marketing-text">
			  <div className="mkt-seo">
				  <h3>SEO</h3>
				  <span>
				  	Domine los resultados de búsqueda y atraiga clientes potenciales calificados. Nuestra experiencia en SEO hace que su sitio web sea visto por la audiencia adecuada.
				  </span>
			  </div>
			  <div className="mkt-sem">
				  <h3>SEM</h3>
				  <span className="sem">
				  Libere el poder de la publicidad paga: vaya más allá del alcance orgánico con nuestra experiencia combinada en SEM (marketing en motores de búsqueda) y anuncios en redes sociales. Creamos campañas dirigidas para atraer a la audiencia adecuada, generando tráfico, conversiones y, en última instancia, crecimiento para su negocio.
				  </span>
			</div>
			  <div className="mkt-local">
				  <h3>Marketing Hiper-Local</h3>
				  <span className="local">
				  Convierta las búsquedas locales en clientes leales: atraiga más tráfico y llamadas telefónicas con nuestros potentes servicios de optimización de SEO local y Google My Business. Le ayudaremos a dominar los resultados de búsqueda locales, mostrar sus críticas positivas y hacer que los clientes de su área descubran su empresa.
				  </span>
			  </div>
		  </div>
	</div>
	  
  )
}

export default marketing