import React from 'react';
import './languages.css'; 
import C from './images/csharp.png'; 
import PHP from './images/php3.png'; 
import Javascript from './images/javascript.png'; 
import Typescript from './images/typescript.png'; 
import SqlServer from './images/sqlserver.png'; 


const languages = () => {
	return (
		<div className='container-languages' id='languages'>
			<h3>Languajes de Programación</h3>
		<div className="languages">
			<div className="languages-item">
				<img src={C} alt="c#" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				<img src={PHP} alt="php" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				<img src={Javascript} alt="php" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				<img src={Typescript} alt="php" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				<img src={SqlServer} alt="php" style={{ width: 120, height: 120 }}/>
			</div>
		</div>
	</div>
  )                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
}

export default languages