import React from 'react';
import './frameworks.css'; 
import Netcore from './images/netcore.png'; 
import Node from './images/nodejs.png'; 
import ReactImg from './images/react.png'; 
import Laravel from './images/laravel.png'; 
import Wordpress from './images/wordpress.png'; 



const frameworks = () => {
  return (
	  <div className="frameworks" id='frameworks'>
		  <h3>Frameworks</h3>
		  <div className="frameworks-images">
		  	<div className="languages-item">
				<img src={Netcore} alt="c#" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				<img src={Node} alt="c#" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				<img src={ReactImg} alt="c#" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				  <img src={Laravel} alt="c#" style={{ width: 50, height: 60 }}/>
			</div>
			<div className="languages-item">
				  <img src={Wordpress} alt="c#" style={{ width: 80, height: 70 }}/>
			  </div>
			  
		  </div>
	</div>
)
}

export default frameworks