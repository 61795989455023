import React from 'react'; 
import "./header.css"; 

const header = () => {
  return (
	 
	<nav className="navbar navbar-expand-lg bg-body-tertiary">
	<div className="container-fluid">
	  <a className="navbar-brand" href="#hero">Solucion-<span className="font-red">e</span></a>
	  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
		<span className="navbar-toggler-icon"></span>
	  </button>
	  <div className="collapse navbar-collapse" id="navbarNavDropdown">
		<ul className="navbar-nav">
			<li className="nav-item">
				<a className="nav-link active" aria-current="page" href="#hero">Home</a>
		  	</li>
		  	<li className="nav-item">
				<a className="nav-link" href="#about">Acerca de</a>
			</li>
			 <li className="nav-item dropdown">
				<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
			  	Que hacemos
				</a>
				<ul className="dropdown-menu">
			 		<li><a className="dropdown-item" href="#development">Desarrollo Full-Stack</a></li>
			  		<li><a className="dropdown-item" href="#marketing">Marketing Digital</a></li>
			  		<li><a className="dropdown-item" href="#languages">Como lo hacemos</a></li>
				</ul>
		  	</li>
			<li className="nav-item">
				<a className="nav-link" href="#plans">Precios</a>
			</li>
			<li className="nav-item">
				<a className="nav-link" href="#testing">Contacto</a>
		  	</li>
		</ul>
	  </div>
	</div>
  </nav>
	  
  )
}

export default header