import React from 'react'; 
import './hero.css';

const hero = () => {
  return (
	  <div className="hero" id='hero'>
		  <h2>Solucion-e</h2>
		  <h1>Desarrollo Web & Marketing Digital</h1>

		  <div className="d-flex gap-2 flex-wrap">
			  <input type="text" name="contact" id="" class="quote-form-control" placeholder='Su Email' />
			  <input type="button" value="COTIZAR" className="btn btn-sm btn-quote-hero" />
		  </div>
	</div>
  )
}

export default hero