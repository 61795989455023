import React from 'react'; 
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

const footer = () => {
  return (
	  <div className='footer'>
		  <div className="logo">
		  	<span class="material-symbols-outlined logo-span"> lightbulb </span>
		  </div>
		  <div className="name">
			  <h3>solucion-<span className='yellow'>e</span></h3>	  
			  <span className='gray'>Desarrollo Web & E-Marketing </span>
		  </div>
		  <div className="socials">
			<div className="network">
				<a href="https://www.facebook.com/DesarrolloDigital" target='_blank' rel='noreferrer'>
					<FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
				</a>
			  </div>
			  <div className="network">
			  <a href="https://twitter.com/MDigiti" target='_blank' rel='noreferrer'>
					<FontAwesomeIcon icon={faX}></FontAwesomeIcon>
					  
				</a>
			  </div>
			  <div className="network">
			  <a href="https://www.linkedin.com/in/marketo-contact/" target='_blank' rel='noreferrer'>
					<FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
				</a>
			  </div>

			  <div className="network">
			  <a href="https://github.com/emarketingcontacto" target='_blank' rel='noreferrer'>
					  <FontAwesomeIcon icon={faGithub} />
				</a>
			  </div>
			  
		  </div>
		  <div className="rights">
		  	<p>2024 &copy;- Solucion-e</p>
		  </div>
	</div>
  )
}

export default footer