import React from 'react'
import './development.css'; 

const development = () => {
  return (
	  <div className="development" id='development'>
		  
		  <div className="dev-text">

			  <span className="divider">
				<span></span>
			</span>
		  
			  <h3>Desarrollo Web Fullstack</h3>
			  <h3 className="text-black">Desarrollo de Paginas Web</h3>
			  <span class="material-symbols-outlined yellow-span">travel_explore</span>
				
			  <span>
				  Somos expertos en <strong>Desarrollo de Software Full-Stack.</strong>
				  <p>Esto implica que dominamos todas las capas de desarrollo, desde la interfaz de usuario (frontend) hasta la base de datos (backend). </p>
				  <p>Ofrecemos soluciones integrales, desde el diseño conceptual hasta la implementación final, creando <strong>productos digitales </strong>robustos y eficientes.</p>
			  </span>

			
			<h3 className="text-black">Desarrollo de Aplicaciones Web a Medida </h3>
			<span class="material-symbols-outlined yellow-span">desktop_windows</span>
			<span>
				  <p>Somos especialistas en <strong>Desarrollo de Aplicaciones Web a Medida </strong> utilizando distintos lenguages de programación, dependiendo de la complejidad del proyecto</p>
				  <p> <strong>Creamos software</strong> a Medida robusto y eficiente para cualquier sistemas operativos.</p>
				  <p>Ofrecemos soluciones personalizadas que satisfacen las necesidades específicas de nuestros clientes, desde <strong>aplicaciones empresariales </strong>hasta herramientas de productividad.</p>
			</span>

		  </div>

		  <div className="dev-image"></div>
	</div>
  )
}

export default development